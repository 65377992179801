<template>
  <a-spin :spinning="crsspinning">
    <ele-table
      :key="'crtable'"
      :columns="tableColumns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      :max-height="tableMaxHeight"
      :height="tableMaxHeight"
      :actionsType="actionsType"
      :IsClear="IsClear"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="24" class="up-search">
        <a-col span="4">
          <a-input v-model="request.keyword" placeholder="姓名/身份证号/手机号" />
        </a-col>
        <a-col span="4">
          <a-range-picker @change="searchDateChange" v-model="rangeDate" :placeholder="[l('开始日期'), l('结束日期')]" valueFormat="YYYY-MM-DD" />
        </a-col>
        <a-col span="4">
          <a-button type="primary" class="up-btn up-blue" icon="search" @click="searchData">查询</a-button>
          <a-button type="default" icon="sync" @click="resetData">重置</a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from '@/components/ele-table';
import { fileDownloadService } from "@/shared/utils";
import DetailInfo from './detail.vue';
import RePurchase from './repurchase.vue';

export default {
  name: 'checkresult',
  mixins: [AppComponentBase],
  components: {
    EleTable
  },
  data () {
    const height = window.screen.availHeight - 250;
    return {
      tableColumns: [],
      tableData: [],
      pageNumber: 1,
      totalItems: 0,
      tableMaxHeight: height,
      actionsType: null,
      crsspinning: false,
      request: {
        maxResultCount: 20,
        skipCount: 0,
        keyword: '',
        startDay: undefined,
        endDay: undefined
      },
      rangeDate: [],
    }
  },
  methods: {
    searchDateChange (e) {
      if (e && e.length > 0) {
        this.request.startDay = e[0];
        this.request.endDay = e[1];
      } else {
        this.request.startDay = undefined;
        this.request.endDay = undefined;
      }
    },
    clearFilterAndRefresh() {
      this.resetData();
      this.searchData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.searchData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.searchData();
    },
    resetData () {
      this.request.keyword = '';
      this.request.startDay = undefined;
      this.request.endDay = undefined;
      this.rangeDate = this.rangeDate.splice(0, this.rangeDate.length);
      this.searchData();
    },
    searchData () {
      this.crsspinning = true;
      this.commonService.post({
        url: '/api/services/app/UPersonInsuranceLog/GetPage',
        params: {
          ...this.request
        }
      }).then(res => {
        this.crsspinning = false;
        this.IsClear = !this.IsClear;
        this.tableData.splice(0, this.tableData.length);
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        res.items.map(item => {
          this.tableData.push(item);
          return null;
        });
      }).catch(e => {
        this.crsspinning = false;
        console.error(e);
      })
    },
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.tableColumns = [
      { title: "姓名", dataIndex: "realName", scopedSlots: { customRender: "realName" }, width: 100, showOverflowTooltip: true },
      { title: "身份证号", dataIndex: "idCard", scopedSlots: { customRender: "idCard" }, width: 100, showOverflowTooltip: true },
      { 
        title: "手机号",
        dataIndex: "phoneNumber",
        sorter: false,
        align: "center",
        width: 120,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "phoneNumber" },
      },
      { 
        title: "保险日期",
        dataIndex: "buyForDay",
        sorter: false,
        align: "center",
        width: 180,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "buyForDay" },
      },
      { title: "创建时间", dataIndex: "createdTime", scopedSlots: { customRender: "createdTime" }, width: 100, showOverflowTooltip: true, type: 'time' },
      { title: "是否已调用保险接口", dataIndex: "isPurchaseStr", scopedSlots: { customRender: "isPurchaseStr" }, width: 100, showOverflowTooltip: true },
      { title: "购买时间", dataIndex: "buyTime", scopedSlots: { customRender: "buyTime" }, width: 100, showOverflowTooltip: true, type: 'time' },
      { title: "购买结果", dataIndex: "isSuccessStr", scopedSlots: { customRender: "isSuccessStr" }, width: 100, showOverflowTooltip: true },
      { title: "入职日期", dataIndex: "inDate", scopedSlots: { customRender: "inDate" }, width: 100, showOverflowTooltip: true, type: 'date' },
      { title: "离职日期", dataIndex: "outDate", scopedSlots: { customRender: "signStatusStr" }, width: 100, showOverflowTooltip: true, type: 'date' },
      { 
        title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          fixed: "right",
          width: 100,
          scopedSlots: { customRender: "actions" },
      }
    ];
    this.actionsType = {
      type: "project",
      isShow: true,
      fns: {
        isShow: true,
        dropList: [
          {
            granted: this.isGranted("Pages.SignCheckResult.CheckDetail"),
            name: this.l("查看详情"),
            fn: (data) => {
              ModalHelper.create(DetailInfo, {
                id: data.id
              }, {
                isChange: true,
                width: "800px",
                heigth: "600px",
              });
            },
          },
          {
            granted: this.isGranted("Pages.InsuranceLog.RePurchase"),
            name: this.l("重新购买"),
            // icon: "rollback",
            // width:'50%',
            fn: (data) => {
              ModalHelper.create(RePurchase, {
                id: data.id
              }, {
                isChange: true,
                width: "800px",
                heigth: "600px",
              }).subscribe(res => {
                if (res && res === true) {
                  this.searchData();
                }
              });
            },
          }
        ],
      },
    };
    this.searchData();
  }
}
</script>
<style scoped>
.up-item-select {
  width: 95%;
}
</style>

