<template>
  <a-spin :spinning="crspinning">
    <div style="width:100%;">
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="copy" />&nbsp;详情查看
        </div>
      </div>
      <a-card :bordered="false" style="width: 95%">
        <a-row :gutter="24">
          <a-col span="6">
            <div class="up-item">
              <span class="up-item-label">姓名：</span>
              <span class="up-item-value">{{datailInfo.realName}}</span>
            </div>
          </a-col>
          <a-col span="6">
            <div class="up-item">
              <span class="up-item-label">身份证号码：</span>
              <span class="up-item-value">{{datailInfo.idCard}}</span>
            </div>
          </a-col>
          <a-col span="6">
            <div class="up-item">
              <span class="up-item-label">手机号：</span>
              <span class="up-item-value">{{datailInfo.phoneNumber}}</span>
            </div>
          </a-col>
          <a-col span="6">
            <div class="up-item">
              <span class="up-item-label">保险日期：</span>
              <span class="up-item-value">{{datailInfo.buyForDay}}</span>
            </div>
          </a-col>

          <a-col span="6">
            <div class="up-item">
              <span class="up-item-label">入职日期：</span>
              <span class="up-item-value">{{getDate(datailInfo.inDate)}}</span>
            </div>
          </a-col>
          <a-col span="6">
            <div class="up-item">
              <span class="up-item-label">离职日期：</span>
              <span class="up-item-value">{{getDate(datailInfo.outDate)}}</span>
            </div>
          </a-col>
          <a-col span="6">
            <div class="up-item">
              <span class="up-item-label">创建时间：</span>
              <span class="up-item-value">{{getDate(datailInfo.createdTime)}}</span>
            </div>
          </a-col>
          <a-col span="6">
            <div class="up-item">
              <span class="up-item-label">购买日期：</span>
              <span class="up-item-value">{{getDate(datailInfo.buyTime)}}</span>
            </div>
          </a-col>

          <a-col span="12">
            <div class="up-item">
              <span class="up-item-label">是否已调用保险接口：</span>
              <span class="up-item-value">{{datailInfo.isPurchaseStr}}</span>
            </div>
          </a-col>
          <a-col span="12">
            <div class="up-item">
              <span class="up-item-label">购买结果：</span>
              <span class="up-item-value">{{datailInfo.isSuccessStr}}</span>
            </div>
          </a-col>

          <a-col span="24">
            <div class="up-item">
              <span class="up-item-label">请求内容：</span>
              <span class="up-item-value">{{datailInfo.requestBody}}</span>
            </div>
          </a-col>
          <a-col span="24">
            <div class="up-item">
              <span class="up-item-label">响应内容：</span>
              <span class="up-item-value">{{datailInfo.responseContent}}</span>
            </div>
          </a-col>

        </a-row>
      </a-card>
    </div>
  </a-spin>
</template>
<script>
  import { ModalComponentBase } from "@/shared/component-base";
  import { CommonServiceProxy } from "@/shared/common-service";
  import moment from "moment";
  export default {
    name: 'checkresultdetail',
    mixins: [ModalComponentBase],
    data () {
      return {
        crspinning: false,
        datailInfo: {},
        commonService: null,
      };
    },
    methods: {
      getDetail() {
        this.crspinning = true;
        this.commonService.get({
          url: '/api/services/app/UPersonInsuranceLog/GetById',
          params: {
            id: this.id
          }
        }).then(res => {
          this.crspinning = false;
          this.datailInfo = res;
        }).catch(e => {
          console.error(e);
          this.crspinning = false;
        });
      },
      getDate(value) {
        if(!value || value === '') {
          return '-';
        }
        if (typeof value === 'date') {
          return moment(date).format('YYYY-MM-DD')
        }
        var val = value.replace('T', ' ');
        return moment(val).format('YYYY-MM-DD');
      }
    },
    created() {
      this.fullData(); // 模态框必须,填充数据到data字段
      this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
      this.getDetail();
    }
  }
</script>
<style>
.up-item{
  width:100%;
  height: 90px !important;
  margin-top: 7px;
}
.up-item-label {
  display: block;
  font-size: 14px;
}
.up-item-value {
  display:block;
  margin-top: 5px;
  font-weight: 600;
  font-size: 12px;
  color: #93a1e7 !important;
  height: 80px !important;
  max-height: 80px !important;
  overflow-y:hidden;
}
</style>