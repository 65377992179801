<template>
  <a-spin :spinning="crspinning">
    <div style="width:100%;">
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="copy" />&nbsp;重新购买
        </div>
      </div>
      <a-card :bordered="false" style="width: 95%">
        <a-row :gutter="24">
          <a-col span="12">
            <div class="up-item">
              <span class="up-item-label">岗位：</span>
              <span class="up-item-value">
                <a-input v-model="positionName" placeholder="请输入岗位" />
              </span>
            </div>
          </a-col>
          <a-col span="12">
            <div class="up-item">
              <span class="up-item-label">险种：</span>
              <span class="up-item-value">
                <a-input v-model="planName" placeholder="请输入险种" />
              </span>
            </div>
          </a-col>

          <a-col span="24">
            <div class="up-item1">
              <a-button type="primary" @click="handleRePurchase">重新购买</a-button>&nbsp;&nbsp;
              <a-button @click="handleClose">关闭</a-button>
            </div>
          </a-col>

        </a-row>
      </a-card>
    </div>
  </a-spin>
</template>
<script>
  import { ModalComponentBase } from "@/shared/component-base";
  import { CommonServiceProxy } from "@/shared/common-service";
  import moment from "moment";
  export default {
    name: 'repurchaseinsurance',
    mixins: [ModalComponentBase],
    data () {
      return {
        crspinning: false,
        datailInfo: {},
        commonService: null,
        positionName: '',
        planName: ''
      };
    },
    methods: {
      getDetail() {
        this.crspinning = true;
        this.commonService.get({
          url: '/api/services/app/UPersonInsuranceLog/GetById',
          params: {
            id: this.id
          }
        }).then(res => {
          this.crspinning = false;
          this.datailInfo = res;
          if (res.requestBody) {
            let json = JSON.parse(res.requestBody);
            this.positionName = json.PositionName;
            this.planName = json.PlanName;
          }
        }).catch(e => {
          console.error(e);
          this.crspinning = false;
        });
      },
      getDate(value) {
        if(!value || value === '') {
          return '-';
        }
        if (typeof value === 'date') {
          return moment(date).format('YYYY-MM-DD')
        }
        var val = value.replace('T', ' ');
        return moment(val).format('YYYY-MM-DD');
      },
      handleClose() {
        this.close();
      },
      handleRePurchase () {
        if (!this.positionName || this.positionName == '') {
          abp.message.warn('请填写岗位');
          return false;
        }
        if (!this.planName || this.planName == '') {
          abp.message.warn('请填写险种');
          return false;
        }
        this.crspinning = true;
        this.commonService.post({
          url: '/api/services/app/UPersonInsuranceLog/RePurchase',
          params: {
            id: this.id,
            Position: this.positionName,
            PlanName: this.planName
          }
        }).then(res => {
          this.crspinning = false;
          if (res && res != '') {
            abp.message.error(res);
            return false;
          } else {
            abp.message.success('重新购买成功');
            this.success(true);
          }
        }).catch(e => {
          console.error(e);
          this.crspinning = false;
        });
      }
    },
    created() {
      this.fullData(); // 模态框必须,填充数据到data字段
      this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
      this.getDetail();
    }
  }
</script>
<style>
.up-item{
  width:100%;
  height: 60px;
  margin-top: 7px;
}
.up-item1 {
  width:100%;
  height: 60px;
  line-height: 60px;
  margin-top: 27px;
  text-align: right;
}
.up-item-label {
  display: block;
  font-size: 14px;
}
.up-item-value {
  display:block;
  margin-top: 5px;
  font-weight: 600;
  font-size: 12px;
  color: #c9bfbf;
  height: 50px;
  max-height: 50px;
  overflow-y:hidden;
}
</style>